@media (max-width: 1280px) {
    /* .cardimage {
        width: 200px;
        left: 12%;
        top: -31%;
    } */

    .container-image {
        padding-top: 35px;
        padding-bottom: 35px;
        height: auto;
    }

    .card1 {
        height: 460px;
        padding: 1.5rem 0rem;
    }

    .imagecontainer {
        height: 100%;
        width: 100%;
    }

    .col-title {
        padding: 1rem 30px;
    }

    .gradient-border {
        left: 60%;
    }

    .card {
        padding: 1rem;
        max-width: 90%;
        gap: 1rem;
        margin-top: -2rem;
    }

    .featureTitle {
        text-wrap: nowrap;
    }

    .menu-card1 {
        width: auto;
    }

    .fixed-column {
        top: 105px
    }

    .cartcheck-button {
        /* margin: 10px 0px 0px 14rem; */
    }

    .footer-col1 {
        gap: 0rem;
    }
}

@media (max-width: 1020px) {
    .navLinks {
        padding: 0 40px;
    }

    .featureTitle {
        margin-top: 30px;
        margin-left: 0px;
    }

    .featureText {
        margin-top: 20px;
    }

    .menu-check-button1 {
        left: 50%;
    }

    /* .menu-gradient-border {
        left: 53%;
        top: -34%;
    } */

    .card1 {
        margin-top: 14rem;
        height: 365px;
        width: auto;
    }

    .gradient-border {
        left: 58% !important;
        top: -44%;
    }

    /* .cardimage {
        left: 35% !important;
        top: -31% !important;
    } */

    .card {
        display: flex;
        flex-direction: column !important;
    }
    .user-order-detail-cart{
        margin-bottom: 0px !important;
    }
    .cart-title-user-order{
        margin-top: 50px !important;
    }

    .menu-gradient-border {
        right: 32%;
    }
}


@media (max-width: 760px) {
    .menu-gradient-border {
        right: 16%;
        top: -45%;
    }
    .container-image {
        padding: 0px;
        overflow-x: hidden;
        margin-top: 77px;
    }

    .primaryButton {
        margin-top: 25px;
    }

    /* .featureTitle {
        margin-top: -80px !important;
        margin-left: 70px !important;
    } */

    .featureText {
        margin-top: 30px !important;
    }

    .card {
        padding: 0 1.5rem 1.5rem 1.5rem !important;
        margin-top: -6rem !important;
        /* margin-left: 35px !important; */
        display: flex;
        flex-direction: column !important;
    }

    /* .cardimage {
        left: 25% !important;
        top: -33% !important;
    } */

    /* .gradient-border {
        top: -34% !important;
        left: 55% !important;

    } */

    .paragraph {
        backdrop-filter: blur(2px);
        border-radius: 10px;
        background: #80808052;
        color: white;
        padding: 1rem;
        margin: 0;
    }

    .logoContainer {
        margin-left: 4rem !important;
    }

    .loginButton {
        margin: 5px -20px 5px 0px !important;
    }

    .header {
        margin: 0 !important;
    }

    .navLinks {
        margin-left: 2rem !important;
    }

    .cart {
        padding: 7px !important;
        margin-bottom: 5rem !important;
    }

    .cart-icon-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        background-color: #4caf50;
        border: none;
        border-radius: 50%;
        padding: 10px;
        z-index: 9999;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .cart-icon-button i {
        color: white;
        margin: 5px;
    }

    .Modal-View {
        padding: 0 !important;
    }

    .cart-mobile {
        max-height: 56vh;
        overflow: auto;
    }

    .tabs {
        display: block;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid #ddd;
    }

    .user-info p {
        margin: 20px 0;
        font-size: 12px;
        color: #333;
        display: flex;
        align-items: center;
    }

    .footer-name {
        display: block !important;
    }
    .form-container {
        width: fit-content;
        height: auto;
        margin: 0 auto;
        font-family: Arial, sans-serif;
    }

    /* .menucard-top {
        margin-left: 30px;
    } */
    .footer_page_parent_class {
        margin: 10px;
    }

    /* .menu-gradient-border {
        position: absolute;
        top: -35%;
        left: 65%;
        width: 80px;
        height: 80px;
        border-radius: 100px;
        background: black;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        font-family: "Jost-Bold.ttf";
        font-size: 14px;
    } */

    .order-button {
        margin-left: 3.5rem;
    }

    .card-content {
        width: 200px;
    }

    .cart-count {
        top: 0% !important;
        right: unset !important;
    }

    .footer-header {
        font-size: 2rem;
    }

    .header1 {
        font-size: 2rem;
        margin: 0;
    }

    .footer-col1 {
        display: block;
    }

    .footer-header-card {
        margin-bottom: 1rem;
        /* margin-left: 20px; */
        margin-top: 1rem;
    }

    /* .footer-link {
        margin-left: 20px;
    } */

    .footer-space {
        margin-left: 20px;
    }

    .footer-header-card1 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .footer-header-card2 {
        margin-top: 1.5rem;
        font-size: 1rem;
    }

    /* .form-group1 {
        display: block;

    } */
    .form-group1 {
        display: flex;
        flex-direction: column !important;
        align-items: start;
        /* gap: 10px; */
    }

    .submit-button1 {
        margin: 10px 0px
    }

    .subcribe_div_parent {
        margin-left: 0px;
    }

    .card1 {
        margin-top: 11rem;
        height: 365px;
    }

    .terms-header {
        font-Size: 3rem;
        margin-top: 35px;
    }

    .menu-image {
        margin-top: 77px;
    }

    .checkout-image {
        margin-top: 120px;

    }

    .footer {
        padding: 30px;
    }

    .logo-1 {

        padding-left: 0px;

    }

    .footer-paragraph {
        padding: 0rem;
        margin-top: 10px;
    }

    .footer-col {
        list-style: none !important;
        margin-left: 0px !important;
    }

    .logo {
        width: 245px !important;
        padding-left: 20px !important;
    }

    .navbar-brand .logo {
        height: 50px;
    }

    .menu-check-button1 {
        left: 50%;
    }

    .navbar-collapse {
        padding-left: 25px;
    }
    .navbar-nav{
        display: flex;
        align-items:start;
    }
    .user-order-detail-cart{
        margin-bottom: 0px !important;
    }
    .cart-title-user-order{
        margin-top: 30px !important;
    }

    .menu-card1 {
        display: flex;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
        flex-direction: row !important;
        margin-top: 10rem;
        background: #f1f1f1;
        height: 370px;
        width: auto;
        padding: 1.5rem;
        border: 2px solid #fff;
        border-radius: 25px;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        text-align: center;
        gap: 5rem;
      }

      .scroll-icon {
        bottom: 90px;
        right: 30px;
      }
      
      .stepper-progress-container {
        display: block;
      }

      .stepper-progress-step:last-child {
        align-items: center;
    }

    .stepper-progress-container::after {
        display: none;
    }
}





@media (max-width: 400px) {
    .paragraph {
        backdrop-filter: blur(2px);
        border-radius: 10px;
        background: #80808052;
        color: white;
        padding: 1rem;
        margin: 1rem;
    }
}

/* Mobile styles (up to 576px) */
@media (max-width: 576px) {

    .navabar {
        flex-direction: column;
    }

    nav.navbar.row {
        padding: 0;
    }

    ul.navLinks {
        position: relative;
        /* position-area: center; */
    }

    .container-image {
        height: 100vh;
    }

    .cardcontainer {
        margin: 70px 0 0 0;
    }


    .foodcard {
        justify-content: space-between;
        row-gap: 5rem;
        margin-top: 5rem;
    }


    .card {
        display: flex;
        flex-direction: column !important;
    }


    .footerimg {
        text-align-last: center;
        margin-bottom: 1rem;
    }

    .image-container {
        padding-left: 0;
        padding-right: 0;
    }

    img.imagecontainer {
        width: 80%;
        padding: 0rem;
        border-radius: 15px;
        margin-bottom: 0rem;
    }



    .logo {

        width: 200px;
        padding-left: 0;
    }

    .actions {
        margin: auto;
    }


    .tab-button {
        font-size: 0.9rem;
        padding: 0.4rem 0.8rem;
    }

    .tab-button.active,
    .tab-button.inactive {
        font-size: 1.1rem;
        padding: 0.4rem 1.5rem;
    }

    .tab-button.active {
        border-radius: 20px;
    }

    .tab-button.inactive {
        border-radius: 20px;
    }

    .secondaryButton {
        background: linear-gradient(90deg, black, grey, black);
        color: white;
    }

    /* .feature {
        flex-direction: column;
    } */

    .featureTitle {
        text-align: center;
    }

}