@font-face {
  font-family: "Jost-Light.ttf";
  src: url("../assets/fonts/Jost-Light.ttf");
}

@font-face {
  font-family: "Jost-Regular.ttf";
  src: url("../assets/fonts/Jost-Regular.ttf");
}

@font-face {
  font-family: "Jost-Bold.ttf";
  src: url("../assets/fonts/Jost-Bold.ttf");
}

* {
  font-family: "Jost-Light.ttf";
}

@font-face {
  font-family: "Brown\ Falco\ DEMO\ otf.otf";
  src: url("../assets/fonts/Brown\ Falco\ DEMO\ otf.otf");
}

.navbar-brand .logo {
  height: 60px;
  width: 300px;
  object-fit: fill;
}

.cart-count {
  position: absolute;
  top: 1px;
  right: -1px;
  background-color: red;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-weight: bold;
  text-align-last: center;
  align-content: center;
}

.navbar-toggler {
  border: none;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-item {
  font-weight: 700;
  color: black !important;
}

.dropdown-menu {
  border: 1px solid black;
}

.dropdown-menu .dropdown-item {
  font-size: 18px;
  font-weight: 800;
}

.dropdown-menu .dropdown-item:hover {
  color: rgb(255, 0, 0);
  /* background-color: rgb(53, 53, 53); */
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -50px;
  margin-top: 0.125rem;
}

/* navbar css ends */

.footer-col {
  list-style: none !important;
}

.footer-line {
  margin: 0px !important;
  color: #fefefe;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

.footer-col1 {
  display: flex;
  padding: 8rem 2rem;
}

.footer-link {
  text-decoration: none;
  color: #929292;
  font-size: 1rem;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 2;
}

.footer-link-active {
  text-decoration: underline;
  text-underline-position: under;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 2;
}

.footer-link:hover,
.footer-icon i:hover {
  color: #e82429 !important;
}

.footer_logo {
  width: 200px;
}

.forgot-link {
  text-decoration: none;
  color: #171717;
  font-size: 1rem;
  font-weight: 500;
  list-style: none !important;
  cursor: pointer;
}

.cartIcon {
  height: auto;
  width: 40px;
  cursor: pointer;
}

.loginButton {
  padding: 0.5rem 1.5rem;
  background-color: transparent;
  border: revert;
  border-radius: 20px;
  color: black;
  font-size: 1rem;
  cursor: pointer;
}

.container-image {
  background-image: url("../assets/images/Group\ 1597883580.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  padding-top: 50px;
  margin-top: 90px;
}

.login-image {
  background-image: url("../assets/images/Login.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.login-card {
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 30px 6px;
  flex-direction: row !important;
  /* margin-top: 8rem;
  margin-left: 10rem; */
  margin: auto;
  background: #ffffff;
  height: 480px;
  width: 420px;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
  gap: 5rem;
}

.changelogin-card {
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 30px 6px;
  flex-direction: row !important;
  margin-top: 9rem;
  margin-left: 10rem;
  background: #ffffff;
  height: 420px;
  width: 420px;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
  gap: 5rem;
}

.otp-card {
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 30px 6px;
  flex-direction: row !important;
  margin-top: 11rem;
  margin-left: 10rem;
  background: #ffffff;
  height: 375px;
  width: 420px;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
  gap: 5rem;
}

.forgot-card {
  display: flex;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 30px 6px;
  flex-direction: row !important;
  margin-top: 11rem;
  margin-left: 10rem;
  background: #ffffff;
  height: 360px;
  width: 420px;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 10px;
  text-align: center;
  gap: 5rem;
}

.menu-image {
  background-image: url("../assets/images/Group\ 1597883582.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 38vh; */
  padding: 50px 0px 20px;
  margin-top: 95px;
}

.footer-image {
  background-image: url("../assets/images/Group\ 39985.png");
  background-repeat: no-repeat;
  height: auto;
  padding: 40px 0px;
  overflow-x: hidden;
  background-size: cover;
}

.container {
  /* text-align: center;
    padding: 5rem;
    min-height: auto; */
}

.header {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 48px;
  color: rgb(0 0 0);
  margin: 20px;
}

.terms-header {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 4rem;
  color: rgb(0 0 0);
  margin: 50px;
}

.footer-header-card {
  font-family: "Jost-Bold.ttf";
  color: #929292;
  margin-top: 1.5rem;
}

.footer-icon {
  gap: 1rem;
}


.footer-icon1 {
  font-size: 1.8rem;
  color: #929292;
}

.footer-icon2 {
  margin-left: 8px;
  width: 15px;
  height: 15px;
  color: #929292;
  margin-top: 5px;
}

.footer-header-card1 {
  margin: auto;
  font-family: "Jost-Bold.ttf";
  color: #929292;
  text-wrap: nowrap;
}

.footer-space {
  margin-left: -5rem;
}

.footer-header-card2 {
  margin-top: 2.5rem;
  font-family: "Jost-Bold.ttf";
  color: #bdbdbd;
  text-wrap: nowrap;
}

.footer {
  background: #212121;
  overflow-x: hidden;
  padding: 30px;
}

.footer-header {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 4rem;
  color: rgb(0 0 0);
}

.header1 {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 48px;
  color: rgb(0 0 0);
  margin: 0;
}

.header-title {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 48px;
  color: rgb(0 0 0);
  text-align: start;
}

.header1-title {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 48px;
  color: rgb(255, 0, 0);
  text-align: start;
}

.paragraph-card {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: start;
}

.paragraph {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.footer-paragraph {
  font-size: 1rem;
  color: #929292;
  padding: 1rem 0;
  margin-bottom: 0rem;
  overflow-x: hidden;
}

.footer-paragraph-new {
  font-size: 1rem;
  color: #929292;
  overflow-x: hidden;
  margin-bottom: 0;
}

.footer-contact {
  color: red !important;
  font-weight: bolder !important;
}

.cart-paragraph {
  font-size: 1.2rem;
  color: #333;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.marquee-container {
  white-space: nowrap;
  background-color: red;
  padding: 7px 0;
  font-size: 1rem;
  color: #ffffff;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.footer-para {
  margin-left: 8px;
  font-size: 1rem;
  color: #d0d0d0;
  /* margin-top: 3.5rem; */
}

.cardcontainer {
  margin: 70px 0 70px 0;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.imagecontainer {
  width: 100%;
  height: 100%;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.primaryButton {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: rgb(255 255 255);
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  gap: 0.5rem;
}

.container-menu {
  text-align: center;
  margin-top: 2rem;
}

.menuGrid {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.menuItem {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
}

.icon {
  font-size: 1.2rem;
  color: rgb(255 255 255);
  margin-top: 1px;
}

.icon-order {
  font-size: 1.2rem;
  margin-top: 1px;
}

.login-icon {
  font-size: 1.2rem;
  color: rgb(38, 37, 37);
}

.scroll-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: white;
  border: none;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  height: 40px;
  width: 40px;
}

.scroll-icon.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-icon:hover {
  background-color: #444;
}

/* .additional-text {
  position: absolute;
  font-size: 1.2rem;
  right: 20%;
  top: 25%;
  color: #ff0000; 
  font-weight: bold;
}

.additional-text1 {
  margin-top: 10px;
  position: absolute;
  font-size: 1.2rem;
  right: 21%;
  top: 28%;
  color: #ff0000;
  font-weight: bold;
} */

.secondaryButton {
  background: transparent;
  border: 2px solid #333;
  border-radius: 30px;
  color: #333;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s ease;
}

.order-button {
  background: linear-gradient(90deg, black, grey, black);
  border: 2px solid #0000;
  border-radius: 8px;
  color: rgb(255 255 255);
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  /* margin: 0.5rem; */
  transition: all 0.3s ease;
}

.order-button1 {
  background: linear-gradient(90deg, black, grey, black);
  border: 2px solid #0000;
  border-radius: 8px;
  color: rgb(255 255 255);
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: fit-content;
}

.secondaryButton1 {
  background: linear-gradient(90deg, black, grey, black);
  border: 2px solid #0000;
  border-radius: 30px;
  color: rgb(255 255 255);
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin: 0.5rem;
  transition: all 0.3s ease;
}

.continue-button {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border-radius: 8px;
  color: rgb(255 255 255);
  padding: 0.3rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.view-button {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border-radius: 8px;
  color: rgb(255 255 255);
  padding: 0.5rem 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 5rem;
}

.submit-button {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border-radius: 8px;
  color: rgb(255 255 255);
  padding: 0.5rem 2rem 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 5rem;
  margin-left: 20px;
}

.check-button {
  position: absolute;
  margin-left: -7rem;
  background: transparent;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem;
  width: 100px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgb(255 255 255);
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border: 2px solid #8d1919;
}

.col-title {
  padding: 3rem 30px;
}

.check-button2 {
  position: absolute;
  margin-left: -60px;
  background: transparent;
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
}

.menu-check-button1 {
  margin-top: auto;
  background: transparent;
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
  position: absolute;
  bottom: 20px;
  left: 42%;
  transform: translateX(-50%);
}


.check-button1 {
  margin-top: auto;
  background: transparent;
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
  position: absolute;
  bottom: 20px;
  /* left: 50%; */
  transform: translateX(-50%);
}

.otp-button1 {
  position: absolute;
  margin-top: 20px;
  background: transparent;
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: #333;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
}

.buttonGroup {
  margin-top: 1rem;
}

.button-res {
  padding-top: 35px;
}

.card {
  display: flex;
  flex-direction: row !important;
  margin-top: -6rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  gap: 5rem;
}

.card1 {
  display: flex;
  flex-direction: column !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  margin-top: 6rem;
  background: #f1f1f1;
  height: 430px;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 25px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: center;
  gap: 1rem;
}

.menu-card1 {
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  flex-direction: row !important;
  margin-top: 10rem;
  background: #f1f1f1;
  height: 450px;
  width: 100%;
  padding: 1.5rem;
  border: 2px solid #fff;
  border-radius: 25px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: center;
  gap: 5rem;
}

.footer-icon1:hover {
  transform: scale(1.1);
}

.menu-card1:hover {
  background-color: white !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.card1:hover {
  background-color: white !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.card-body {
  padding-top: 70px;
  flex-grow: 1;
}

.card-title {
  font-family: "Brown\ Falco\ DEMO\ otf.otf";
  text-wrap: wrap;
  white-space: normal;        
  overflow-wrap: break-word;  
}

.login-title {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  text-wrap: nowrap;
  margin-top: -40px;
  margin-bottom: 35px;
  width: 340px;
}

.login-button {
  background: transparent;
  border: 2px solid #8d1919;
  width: 333px;
  height: 44px;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
}

.login-icon-input {
  display: flex;
  align-items: center;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 5px 10px;
  height: 40px;
  width: 340px;
  text-wrap-mode: nowrap;
}

.card-text {
  margin-top: 25px;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}

.featureIcon {
  width: 70px;
  height: auto;
  margin-top: 30px;
}

.cardimage {
  position: absolute;
  height: auto;
  left: 50%;
  top: -10%;
  z-index: 1;
  width: 200px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.featureTitle {
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 0.5rem;
  text-align: justify;
}

.featureText {
  font-size: 1rem;
  color: #555;
  text-align: justify;
}

.menucontainer {
  background-image: url("../assets/images/Group\ 1597883581.png");
  text-align: center;
  margin-top: 35px;
}

.checkout-image {
  background-image: url("../assets/images/Group\ 1597883581.png");
  text-align: center;
  padding: 2rem;
  margin-top: 85px;
}

.menuHeading {
  font-family: "Brown\ Falco\ DEMO\ otf.otf" !important;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.tabContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.menu-tabContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button:hover {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  color: white !important;
  transition: background-color 0.3s, color 0.3s;
}

.tab-button.active {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: white;
  border: 2px solid #ffffff;
  border-radius: 30px;
  padding: 0.5rem 3rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.inactive {
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  border-radius: 30px;
  padding: 0.5rem 3rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-main {
  display: flex;
  flex-direction: column !important;
  margin-top: -6rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  gap: 5rem;
}

.gradient-border {
  position: absolute;
  top: -33%;
  left: 66%;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background: black;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Jost-Bold.ttf";
  font-size: 14px;
}

.menu-gradient-border {
  position: absolute;
  right: 17%;
  top: -37%;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background: black;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Jost-Bold.ttf";
  font-size: 14px;
}

.price-details {
  display: flex;
  flex-direction: column;
}

.price-amount {
  font-size: 13px;
  font-weight: bold;
}

.price-weight {
  font-size: 12px;
  font-weight: 500;
}

.price-line {
  height: 2px !important;
  margin: 0.5rem -8px;
  color: #f1f1f1;
  border: 0;
  opacity: 0.75;
}

.menucard-top {
  margin-top: 215px;
  /* margin-left: 20px; */
}

.radio-label {
  display: contents;
}

.address-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.2s, box-shadow 0.2s;
}

.address-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 600px;
  text-align: left;
}

.card-row p {
  margin: 0;
  text-wrap-style: balance;
}

.card-row-bold {
  margin-top: 15px;
  display: flex;
  gap: 1.5rem;
  font-weight: bold;
}

.card-actions {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btnnew {
  padding: 2px 10px;
  border: none;
  cursor: pointer;
}

.btn-edit {
  background-color: #4caf50;
  color: #fff;
}

.btn-delete {
  background-color: #f44336;
  color: #fff;
}

.delivery-form {
  margin-top: 12px;
}

.cart {
  margin-top: 7rem;
  margin-bottom: 5rem;
  border: 1px solid black;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* z-index: 5000; */
  background: #f1f1f1;
}

.cart-checkout {
  margin-top: 1rem;
  margin-bottom: 5rem;
  border: 1px solid black;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* z-index: 5000; */
  background: #f1f1f1;
}

.cart-title {
  font-family: "Jost-Bold.ttf";
  color: #b70f0f;
  margin: 0;
  text-align: center;
}

.cart-icon {
  margin-top: 40px;
}

.cart-checkout-button {
  background: transparent;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem;
  width: 100px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgb(255 255 255);
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border: 2px solid #8d1919;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.cart-table th {
  padding: 10px;
  text-align: left;
  background-color: #f4f4f4;
}

.cart-table td {
  padding: 10px;
  text-align: left;
}

.cart-table tfoot td {
  font-weight: bold;
  padding: 10px;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  padding: 0;
  border-radius: 5px;
  margin: 0px 5px;
  font-size: 14px;
  background: grey;
  color: white;
  width: 30px;
  height: 30px;
}

.quantity-control span {
  font-size: 16px;
}

.cartcheck-button {
  /* margin: 20px 0px 0px 24rem; */
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgb(255 255 255);
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
}

.cart-table hr {
  border: 1px solid #ccc;
  margin: 10px 0;
}

.fixed-column-checkout {
  position: sticky;
  top: 110px;
  overflow-y: auto;
}

.fixed-column-menu {
  position: sticky;
  top: 120px;
  overflow-y: auto;
}

.scrollable-column {
  overflow-y: auto;
  overflow-x: hidden;
}

.nav-scroll {
  position: fixed;
  background-color: red;
  color: white;
  font-weight: bold;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5000;
}

.item-info {
  display: flex;
  align-items: center;
}

.price-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.price-name {
  font-size: 16px;
  font-weight: bold;
}

.price-weight {
  font-size: 14px;
}

.form-container {
  /* width: 600px; */
  height: auto;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.otp-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.otp-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  width: 370px;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.loginotp-input {
  width: 4em !important;
  height: 60px;
  margin-right: 20px;
  padding: 6px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  transition: border 0.3s;
  animation: fadeIn 0.5s ease;
}

.otp-input:focus {
  outline: none;
  animation: scaleUp 0.2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Jost-Regular.ttf";
}

button:hover {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
}

button+button {
  background-color: #f44336;
  margin-left: 10px;
}

button+button:hover {
  background-color: #e53935;
  color: #f1f1f1;
}

.error-message {
  color: red;
  font-size: 12px;
}

.tab-item {
  cursor: pointer;
  padding: 5px 5px;
  color: #666;
  text-align: center;
  font-size: 16px;
  font-family: "Arial", sans-serif;
  transition: all 0.3s ease;
  border-radius: 5px;
  margin: 0 5px;
  list-style: none;
}

.tab-item.active {
  font-weight: bold;
  color: #333;
  color: rgb(255 255 255);
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  border-bottom: 2px solid transparent;
  border-bottom: none;
}

.form-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f1f1f1;
}

.form-group {
  margin-bottom: 15px;
}

.loginform-group {
  margin-top: 40px;
}

.icon-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  height: 50px;
}

.icon-order-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  height: 50px;
  width: 380px;
}

.icon-order-input input {
  border: none;
  outline: none;
  background-color: white;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-size: 18px;
}


.payment-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  height: 50px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logout-modal {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  overflow: hidden;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
}

.delete_para_model {
  font-size: 24px;
  font-weight: bold;
  color: rgb(186, 24, 24);
  margin-bottom: 20px;
  animation: popUp 1s ease-out;
}

@keyframes popUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.gif-container {
  margin: 20px 0;
}

.fireworks-gif {
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.payment-input label {
  margin-left: 10px;
}

.icon-input .icon {
  margin-right: 10px;
  color: #888;
  font-size: 1.3rem;
}

.icon-input input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  background: #f1f1f1;
  font-weight: 600;
  font-size: 18px;
}

.payment-input input {
  border: none;
  outline: none;
  font-size: 16px;
  background: #f1f1f1;
  font-weight: 600;
  font-size: 18px;
}

.login-icon-input input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  background: #ffffff;
  font-weight: 600;
  font-size: 18px;
  margin-left: 7px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.form-footer {
  text-align: right;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn:hover {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  color: white;
}

.btn-continue {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
}

.btn-submit {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
}

.form-group1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.icon-input-email {
  display: flex;
  align-items: center;
  position: relative;
}

.icon-email {
  position: absolute;
  left: 8px;
  font-size: 18px;
  color: #666666;
}

.footer_subcribe_box {
  padding: 6px 0px 7px 30px;
  font-size: 16px;
  width: 280px;
  background: transparent;
  color: #f1f1f1;
  border: 1px solid #e7e7e7;
}

.subcribe_div_parent {
  margin: auto;
  justify-content: center;
}

input[type="emailid"] {
  padding: 0px;
  font-size: 16px;
  width: 300px;
  background: transparent;
  color: #070707;
  border: 1px solid #e7e7e7;
}

.submit-button1 {
  padding: 10px 20px;
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}

.form-groupnew {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.icon-inputnew {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  background: #f1f1f1;
}

.overflow-hidden {
  overflow: hidden;
}

.primaryButton .icon {
  animation: heartbeat 1.5s infinite;
  transform-origin: center;
  fill: white;
}

@keyframes heartbeat {

  0%,
  100% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}

.iconnew {
  margin-left: 10px;
  font-size: 16px;
}

.dropdown {
  border: none;
  background: transparent;
  font-size: 1.2rem;
  width: 100%;
  outline: none;
  padding: 10px;
  appearance: none;
  cursor: pointer;
}

.dropdown:focus {
  outline: none;
  border: none;
}

.icon-inputnew::after {
  content: "▼";
  position: absolute;
  right: 10px;
  font-size: 12px;
  color: #888;
  pointer-events: none;
}

/* ===========================================(   Sidebar   )================================================= */
.sidebar-menu {
  cursor: pointer;
  margin-left: 15px;
}

.sidebar-menu>p {
  margin: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------sidebar-scroll---------------------- */
.menus::-webkit-scrollbar {
  display: none;
}

/* =============================( page content occupying full available height   )========================= */

.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin: 18px;
  padding: 30px;
  height: 84vh;
  overflow-y: auto;
}

.page-content {
  flex: 1;
}

.layout-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #80808026;
}

.content-body {
  display: flex;
  flex-grow: 1;
}

/* =============================( To position sidebar-header-page content  )================================ */

.sidebar {
  width: 22rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.sidebar_admin_menus {
  padding: 1px 0px;
  margin: 5px 0;
  font-weight: 600;
  font-size: 17px;
  font-family: "Jost-Medium.ttf";
  cursor: pointer;
  width: 100%;
  background-color: #ffffff;
  color: black !important;
  text-decoration: none;
}

.sidebar_admin_menus:hover {
  border-radius: 5px;
  text-decoration: none;
  font-family: "Jost-Regular.ttf";
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 4px;
}

.sidebar_admin_menus_active {
  background-color: #e82429;
  text-decoration: none;
  color: white !important;
  padding: 1px 0px;
  font-weight: 800;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 17px;
  font-family: "Jost-Regular.ttf";
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 4px;
  width: 100%;
}

/* Scrollbar visible only on hover */
.menus {
  /* flex: 1; */
  /* occupy available remaining space*/
  overflow-x: hidden;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
}

.menus:hover {
  flex: 1;
  overflow-y: scroll;
}

/* =================================== */
.admin_header {
  padding: 1.5rem;
  justify-content: end;
  display: flex;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 0px, rgba(0, 0, 0, 0.23) 0px 1px 2px */
}

.admin-logo {
  position: absolute;
  height: auto;
  width: 250px;
  margin-top: 12px;
  padding-left: 15px;
}

.dropdrown-menu {
  padding: 0.5rem 30px !important;
}

.page-content {
  background-color: #f5f5f5;
}

.product-image {
  width: 80px;
  margin-top: -1.75rem;
  padding: 3px;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-trigger img {
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-trigger img:hover {
  transform: scale(1.1);
}

.dropdown-menu1 {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  z-index: 1000;
  padding: 0;
  list-style: none;
  text-wrap: nowrap;
}

.dropdown-menu1 li {
  position: relative;
}

.dropdown-menu1 li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  display: block;
  padding: 10px 20px;
  transition: color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdown-menu1 li a:hover {
  background-color: white;
  color: rgb(255, 0, 0) !important;
  font-weight: bold;
  border-radius: 8px;
}

.profile-image {
  width: 2rem;
  height: auto;
  border: 1px solid white;
  border-radius: 50%;
}

.modal-backdrop {
  background-color: rgb(0 0 0 / 63%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-View {
  background-color: #ffffff;
  padding: 3rem 6rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 5px;
}

/* ===========================================( Tooltip CSS )========================================== */
.tootip-test {
  position: absolute;
  transform: translate(60px);
  display: none;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}

.sidebar-menu:hover+.tootip-test {
  display: block;
}

.dropdown-toggle::after {
  display: none;
}

/* admin panel */
.admin_head {
  color: black;
  font-weight: 600;
  font-family: "Jost-Regular.ttf";
}

.admin_button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
}

.admin_button:hover {
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
}

.model_label_admin {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
  font-size: 18px;
  padding-bottom: 10px;
}

.model_select {
  width: 350px;
  height: 55px;
  padding: 0px 10px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  border: none;
  border-bottom: 1px solid red;
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.model_input_admin {
  width: 350px;
  height: 55px;
  padding: 0px 10px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  border: none;
  border-bottom: 1px solid red;
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.model_input_admin:focus-visible {
  outline: none;
}

.error_message {
  margin-top: 4px;
  color: red;
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
  margin-bottom: 0px;
  font-size: 13px;
}

.tabular_heading h4 {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 20px;
  font-family: "Jost-Regular.ttf";
  overflow-wrap: anywhere;
  text-align: center;
}

.tabular_column_data_parent h6 {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 16px;
  font-family: "Jost-Regular.ttf";
  overflow-wrap: anywhere;
  text-align: center;

}

.tabular_column_parent {
  border-bottom: 1px solid rgb(0, 0, 0);
  padding-bottom: 7px;
}

.action_category .action_icon {
  font-size: 21px;
  color: black;
  cursor: pointer;
}

.delete_para_model {
  font-weight: 700;
  font-size: 22px;
  font-family: "Jost-Regular.ttf";
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.add_product {
  font-weight: 700;
  font-size: 24px;
  font-family: "Jost-Regular.ttf";
}

.add_product_label {
  color: black;
  font-weight: 700;
  font-size: 19px;
  font-family: "Jost-Regular.ttf";
  margin-bottom: 8px;
}

.add_product_input {
  height: 45px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Jost-Regular.ttf";
}

.add_product_input:focus-visible {
  outline: none;
}

.add_product_textarea {
  height: 120px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Jost-Regular.ttf";
}

.address_textarea {
  height: 200px;
  padding: 0px 10px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Jost-Regular.ttf";
}

.add_product_select {
  height: 45px;
  font-weight: 700;
  font-size: 18px;
  font-family: "Jost-Regular.ttf";
  padding-left: 10px;
}

.add_product_select option {
  font-weight: 700;
  font-family: "Jost-Regular.ttf";
}

.order_view_detail {
  font-weight: 700;
  font-size: 18px;
  font-family: "Jost-Regular.ttf";
  color: blue;
  text-decoration: underline;
}

.link_arrow {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 10px;
  border-radius: 30px;
  border: 1px solid rgb(0, 0, 0);
}

.cur_pointer {
  cursor: pointer;
}

.foodcard {
  margin-top: 100px;
}

.order_prod_name {
  font-weight: 700;
  font-size: 20px;
  font-family: "Jost-Regular.ttf";
  margin: auto 0px;
}

.sign-up-submit {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.card-checkout {
  display: flex;
  flex-direction: row !important;
  background: #fff;
  padding: 1.5rem;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  gap: 5rem;
}

.card-message {
  display: flex;
  flex-direction: row !important;
  background: #fff;
  color: red;
  font-weight: bold;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  gap: 5rem;
}

.user-card {
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body-checkout {
  text-align: left;
}

.user-info p {
  margin: 20px 0;
  font-size: 20px;
  color: #333;
  display: flex;
  align-items: center;
}

.user-info i {
  margin-right: 10px;
  font-size: 18px;
  color: var(--fuse-primary-400);
}

.user-info strong {
  color: var(--fuse-primary-400);
  margin-right: 8px;
}

.bold-text {
  font-weight: bolder;
}

.historycard {
  border: none;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.historycard:hover {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
}

.historycard button {
  margin-left: 0px;
}

.card-header {
  font-weight: bold;
  font-size: 1.2rem;
}

.rating {
  color: gold;
}

.delivered-date {
  font-size: 0.9rem;
  font-weight: bold;
}

.text-muted {
  font-size: 0.85rem;
}

.img-small {
  width: 50px;
  height: auto;
  border-radius: 5px;
}

.order-details-container {
  padding: 20px 40px;
  color: #333;
}

.admin-head {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  margin-bottom: 15px;
}

.field-label {
  font-weight: bold;
  color: #555;
}

.field-input,
.field-textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}

.field-value {
  font-size: 14px;
  color: #777;
}

.product-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #555;
}

.product-quantity {
  font-size: 14px;
  color: #777;
}

.summary {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
}

.summary-label {
  font-weight: bold;
  color: #555;
}

.summary-value {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.order-details-content .cart {
  margin-top: 0;
}

.delivery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.delivery-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.delivery-modal-content h5 {
  margin-bottom: 15px;
}

.delivery-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 10px;
}

.foreground-image {
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

.item-quantity-overlay {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 25px;
}

.ordertabs.nav-tabs .nav-link {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}

.nav-link {
  color: #444;
  font-family: "Jost-Light.ttf";
  text-decoration: none;
  margin: 0 5px;
}

.nav-link:hover,
.nav-link-active:hover,
.nav-link-active {
  color: #222;
  font-family: "Jost-Light.ttf";
  text-decoration: underline;
  text-underline-position: under;
  font-weight: bold;
  margin: 0 5px;
}

.cart-scroll-height {
  height: 330px;
  overflow-y: scroll;
}

.empty_cart {
  font-size: 25px;
  font-weight: 700;
  color: red;
}

.footer_page_parent_class {
  margin: 50px 70px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");

:root {
  --stepper-primary: #eeeeee;
  --stepper-secondary: #2192ff;
  --stepper-grey: #808080;
  --stepper-white: #ffffff;
  --stepper-black: #222222;
}

.stepper-wrapper {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  /* background-color: var(--stepper-primary); */
  padding: 1rem;
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.stepper-wrapper .card-header {
  border-radius: 5px;
}

.stepper-progress-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
}

.stepper-progress-container::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 92%;
  top: 43%;
  left: 8%;
  background-color: var(--stepper-grey);
  z-index: 0;
  transform: translateY(-50%);
  border-radius: 2px;
}

.stepper-progress {
  position: absolute;
  left: 5%;
  height: 4px;
  top: 50%;
  width: 0;
  transform: translateY(-50%);
  background-color: var(--stepper-secondary);
  transition: width 0.3s;
  z-index: 1;
  border-radius: 2px;
}

.stepper-progress-step {
  display: flex;
  align-items: center;
  margin-top: 35px;
  flex-direction: column;
  font-weight: 500;
  color: var(--stepper-black);
  z-index: 2;
  text-align: center;
}

.stepper-progress-step:last-child {
  align-items: end;
}

.stepper-progress-step .fa-solid {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--stepper-grey);
  height: 50px;
  width: 50px;
  font-size: 1.5rem;
  border-radius: 50%;
  background-color: var(--stepper-white);
  border: 3px solid var(--stepper-grey);
  margin-bottom: 8px;
  transition: border 0.3s, color 0.3s, background-color 0.3s;
}

.stepper-progress-step .fa-solid.active {
  border: 3px solid var(--stepper-secondary);
  color: var(--stepper-white);
  background-color: var(--stepper-secondary);
}

.stepper-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
}

.stepper-btn {
  padding: 0.5rem 2rem;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: var(--stepper-secondary);
  color: var(--stepper-white);
  border: none;
  transition: background-color 0.3s, opacity 0.3s;
}

.stepper-btn:hover {
  background-color: #187bcd;
}

.stepper-btn.disabled {
  background-color: var(--stepper-grey);
  color: var(--stepper-white);
  cursor: not-allowed;
  opacity: 0.7;
}

.backbutton {
  background: transparent;
  border: 2px solid #8d1919;
  border-radius: 30px;
  color: #333;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #8d1919;
}

.searchWrapper {
  border: 1px solid rgb(55, 55, 55) !important;
  border-radius: 7px;
  min-height: 22px;
  padding: 5px;
  position: relative;
}

.wrapperClassName {
  border: 1px solid #8080805c;
}

.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 3px;
  width: 100%;
}

.chip {
  background: #2f3133 !important;
  font-size: 14px !important;
  padding: 4px 11px !important;
  font-weight: 700 !important;
}

.tabular_heading .category {
  /* font-size: 10px !important; */
}

.order-history-parent-div {
  margin-top: 40px;
}

.ordered-address .label {
  /* font-weight: 800; */
  font-family: "Jost-Regular.ttf";
  font-size: 20px;
  margin-bottom: 0px;
  /* text-decoration: underline; */
  /* border-bottom: 1px solid red; */
}

.ordered-address .data {
  font-weight: 600;
  font-family: "Jost-Regular.ttf";
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.out_ofstock {
  font-weight: 600;
  font-family: "Jost-Regular.ttf";
  font-size: 22px;
  margin-bottom: 0px;
  color: red;
}

.no_order_display {
  color: red;
  font-size: 30px;
  font-family: "Jost-Regular.ttf";
  font-weight: 600;
  text-align: center;
  margin-top: 80px;
}

.order-product-para-admin td {
  color: rgb(0, 0, 0);
  font-family: "Jost-bold.ttf";
}

.user-order-detail-parent {
  margin: 100px 0px 70px;
}

.app_menu_para {
  text-align: center;
  font-family: "Jost-bold.ttf";
  font-size: 18px;
}

.app_menu_para2 {
  text-align: center;
  font-family: "Jost-bold.ttf";
  font-size: 21px;
}

.select_app_menu_para {
  font-size: 15px;
  font-family: "Jost-bold.ttf";
  padding: 10px 20px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  border-radius: 5px;
}

.cursor {
  cursor: pointer;
}

.pincode_input_box {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 800;
  font-family: "Jost-regular.ttf";
  border-radius: 6px;
  border: 1px solid black;
}

.footer_input_box {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 800;
  font-family: "Jost-regular.ttf";
  border-radius: 6px;
  border: 1px solid black;
  margin-bottom: 15px;
}

.footer_input_box_address {
  width: 100%;
  height: 110px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 800;
  font-family: "Jost-regular.ttf";
  border-radius: 6px;
  border: 1px solid black;
  margin-bottom: 15px;
}

.pincode_input_box:focus-visible {
  outline: none;
}

.Admin_add_button {
  width: fit-content;
  padding: 8px 20px;
  background: linear-gradient(90deg, #e40000, rgb(112 46 46));
  color: #f1f1f1;
  border: none;
  border-radius: 4px;
  font-family: "Jost-regular.ttf";
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}

.Admin_add_button:hover {
  background: green;
  color: rgb(255, 255, 255);
}

.selected_menus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.cms_header {
  position: sticky;
  top: -2rem;
  background-color: white;
  z-index: 2;
  padding: 1rem 0rem;
}

.footertable {

  .table th,
  td {
    border-bottom-width: 0 !important;
    text-align: center;
  }
}

.footertable th {
  font-size: 20px;
}

.footertable .footer_head {
  padding-top: 20px !important;
}

.app_logo_image {
  width: 100%;
}

/* .bottom_footer{
  position: absolute;
  width: 100%;
  bottom: 0px;
} */
.password_eye {
  font-size: 20px;
  position: absolute !important;
  right: 5%;
  bottom: 27px;
}

.navbar_menus {
  margin-bottom: 0px;
  font-family: "Jost-Regular.ttf";
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}

.float-end:hover {
  color: white;
  font-weight: 700;
}

.delivery_queries_para {
  color: red;
  font-family: "Jost-medium.ttf";
  font-weight: 800;
  font-size: 20px;

}

.product_add_edit_image img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.logo_div_app {
  margin-top: 60px;
  padding: 0 50px 0 50px;
}

.text-info {
  color: black !important;
  font-size: 14px !important;
  font-family: "Jost-medium.ttf";
  font-weight: 800;
}

.decline_head {
  font-weight: 800;
  text-decoration: underline;
}
.decline_reason_div{
  margin-top: 30px;
  border: 1px solid black;
  padding: 20px;
  border-radius: 10px;
  background-color: wheat;
}
.decline_reason_div p{
  text-align: center;
  font-size: 17px;
  margin-top: 20px;
}
.unauthorized_btn{
  cursor: pointer;
  text-decoration: underline;
  font-weight: 800;
  margin-left: 10px;
}